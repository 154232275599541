import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Get in touch`}</h2>
    <br />
&nbsp;
    <a href="https://www.linkedin.com/in/pranay-narang/" target="_blank" rel="noopener">
    <svg viewBox="0 0 6350 5400" width="40px" height="40px" aria-label="LinkedIn logo">
        <path fill="#2867B2" d="M5878 5385 c-108 -29 -210 -121 -249 -223 -30 -79 -30 -186 1 -267 34 -89 132 -184 219 -213 152 -51 295 -13 404 107 175 195 84 513 -169 591 -75 23 -137 24 -206 5z m236 -70 c64 -30 119 -87 152 -159 29 -62 32 -168 8 -239 -20 -61 -93 -141 -158 -175 -44 -23 -63 -27 -141 -27 -78 0 -97 4 -140 27 -113 59 -168 146 -173 275 -4 72 -1 89 23 140 76 168 262 237 429 158z" />
        <path fill="#2867B2" d="M5840 5029 l0 -201 116 4 116 3 29 33 c54 60 31 161 -42 179 l-29 8 55 82 c30 45 55 85 55 88 0 3 -15 5 -32 5 -30 0 -37 -7 -86 -85 -50 -80 -56 -85 -88 -85 l-34 0 0 85 0 85 -30 0 -30 0 0 -201z m212 -46 c21 -19 23 -52 4 -78 -11 -15 -30 -20 -85 -23 l-71 -4 0 61 0 61 67 0 c48 0 72 -5 85 -17z" />
        <path fill="#2867B2" d="M279 5341 c-111 -36 -205 -125 -251 -238 l-23 -58 0 -2365 0 -2365 23 -58 c47 -114 134 -197 250 -236 61 -21 63 -21 2406 -21 2252 0 2347 1 2404 18 114 36 207 124 254 239 l23 58 0 2365 0 2365 -23 58 c-47 115 -140 203 -254 239 -57 17 -151 18 -2406 17 -2226 0 -2350 -1 -2403 -18z m1311 -2051 l0 -1270 -390 0 -390 0 0 1270 0 1270 390 0 390 0 0 -1270z m1290 608 c0 -368 5 -705 10 -758 19 -180 58 -287 137 -371 85 -91 211 -135 363 -126 228 13 343 137 380 410 6 48 10 354 10 793 l0 714 388 0 389 0 6 -677 c10 -1058 -7 -1249 -132 -1508 -139 -286 -407 -425 -821 -425 -285 0 -540 127 -697 347 -25 35 -49 63 -54 63 -5 0 -9 -74 -9 -170 l0 -170 -375 0 -375 0 0 1270 0 1270 390 0 390 0 0 -662z m-1610 -2249 c147 -23 274 -118 341 -254 33 -68 34 -72 34 -195 0 -122 -1 -127 -34 -195 -45 -92 -124 -171 -216 -216 -68 -33 -73 -34 -195 -34 -122 0 -127 1 -195 34 -180 88 -285 284 -255 479 33 216 211 374 440 391 8 0 44 -4 80 -10z" />
    </svg>
    </a>
&nbsp; &nbsp; &nbsp;
    <a href="https://github.com/Pranay-Narang/" target="_blank" rel="noopener">
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="0 0 64.000000 64.000000" preserveAspectRatio="xMidYMid meet" style={{
        "WebkitFilter": "invert(100%)"
      }} aria-label="GitHub logo">
        <g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
        <path d="M214 630 c-86 -27 -180 -123 -204 -208 -15 -55 -12 -162 6 -215 29
            -87 149 -193 207 -185 17 2 23 11 25 35 3 29 0 33 -20 33 -46 0 -69 13 -98 56
            -34 50 -26 58 13 14 21 -24 35 -30 66 -30 28 0 40 5 45 17 3 10 9 24 12 31 4
            7 -2 12 -16 12 -11 0 -38 9 -59 20 -61 30 -89 122 -56 180 8 14 14 44 15 68 0
            45 12 52 53 30 12 -6 64 -11 117 -11 53 0 105 5 117 11 41 22 53 15 53 -30 1
            -24 7 -54 15 -68 33 -58 5 -150 -56 -180 -21 -11 -47 -20 -59 -20 -21 0 -26
            -10 -11 -26 6 -5 11 -38 13 -74 3 -58 5 -65 25 -68 60 -9 179 99 208 189 24
            70 17 198 -13 256 -33 63 -74 105 -134 138 -46 25 -65 30 -143 32 -49 1 -104
            -2 -121 -7z" />
        </g>
    </svg>
    </a>
&nbsp; &nbsp; &nbsp;
    <a href="https://twitter.com/PranayNarang" target="_blank" rel="noopener">
    <svg viewBox="0 0 32 32" width="40px" height="40px" aria-label="Twitter logo">
        <path fill="#1DA1F2" d="M32 6.076c-1.177 0.522-2.443 0.875-3.771 1.034 1.355-0.813 2.396-2.099 2.887-3.632-1.269 0.752-2.674 1.299-4.169 1.593-1.198-1.276-2.904-2.073-4.792-2.073-3.626 0-6.565 2.939-6.565 6.565 0 0.515 0.058 1.016 0.17 1.496-5.456-0.274-10.294-2.888-13.532-6.86-0.565 0.97-0.889 2.097-0.889 3.301 0 2.278 1.159 4.287 2.921 5.465-1.076-0.034-2.088-0.329-2.974-0.821-0.001 0.027-0.001 0.055-0.001 0.083 0 3.181 2.263 5.834 5.266 6.437-0.551 0.15-1.131 0.23-1.73 0.23-0.423 0-0.834-0.041-1.235-0.118 0.835 2.608 3.26 4.506 6.133 4.559-2.247 1.761-5.078 2.81-8.154 2.81-0.53 0-1.052-0.031-1.566-0.092 2.905 1.863 6.356 2.95 10.064 2.95 12.076 0 18.679-10.004 18.679-18.68 0-0.285-0.006-0.568-0.019-0.849 1.283-0.926 2.396-2.082 3.276-3.398z" />
    </svg>
    </a>
&nbsp; &nbsp; &nbsp;
    <a href="mailto:pranaynarang@gmail.com" target="_blank" rel="noopener">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="40px" height="40px" aria-label="E-Mail logo">
        <path d="M0 0h24v24H0z" fill="none" /><path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z" />
    </svg>
    </a>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      