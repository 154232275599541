import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="StagBIN" link="https://stagbin.tk" bg="linear-gradient(to right, rgba(12,153,59,1) 0%, rgba(155,255,0,1) 150%)" mdxType="ProjectCard">
A free and open-source pastebin service developed completely with the Serverless stack with AWS Lambda, DynamoDB, served with Cloudfront and automated through GitHub Actions.
    </ProjectCard>
    <ProjectCard title="theGulp.in" link="https://theGulp.in" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
E-commerce store for delivering fresh, healthy and preservative-free juices made with our special blends right at your doorstep ever morning.
    </ProjectCard>
    <ProjectCard title="Serverless Video-on-Demand" link="https://github.com/Pranay-Narang/serverless-VOD" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
SaaS VOD service developed over AWS using MediaConvert, Cloudfront, EventBridge, Lambda and Cognito for streaming HLS content securely through a console developed in ReactJS.
    </ProjectCard>
    <ProjectCard title="TINNO-8937 Device Unification" link="https://github.com/TINNO-8937" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 135%)" mdxType="ProjectCard">
Vendor framework unification for Tinno OEMs msm8937/msm8953/msm8996 family of devices helping with faster development cycles for major firmware upgrades.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      